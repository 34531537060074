import idPoaMixin from '@/mixins/idPoa'

export default {
  data() {
    return {
      thaCountryCode: 6163,
      vnmCountryCode: 6991,
      canCountryCode: 4575, //Canada
      chCountryCode: 1, // china
      brlCountryCode: 3636, // Brazil
      ukCountryCode: 6907, // United Kingdom
      laosCountryCode: 4850, // Laos
      mysCountryCode: 5015,
      inCountryCode: 6845, // india
      idnCountryCode: 6877, // indonesia
      hkCountryCode: 7033, // Hong Kong
      phpCountryCode: 4131, // Philippines
      macaoCountryCode: 7069,
      mexicoCountryCode: 5619,
      columbiaCountryCode: 4186,
      chileCountryCode: 7010,
      jpnCountryCode: 5860, // Japan
    }
  },
  mixins: [idPoaMixin],
  mounted() {
    this.$store.dispatch('payment/actionRestrictDeposit')
  },
  methods: {
    notRestricted(type, channel = 1) {
      return this.restrictedDeposit === 0 ? true : this.checkRestricted(type, channel)
    },
    checkRestricted(type, channel) {
      let restricted = false

      this.restrictedDeposit.forEach(function (x, y) {
        if (x.paymentType === type && x.paymentChannel === channel) {
          restricted = true
        }
      })

      return !restricted
    },
    creditClick() {
      this.$router.push({ name: 'creditOrDebit' })
    },
    BanxaCCClick() {
      this.$router.push({ name: 'BanxaCC' })
    },
    internationalClick() {
      this.$router.push({ name: 'internationalSwift' })
    },
    cryptoClick() {
      this.$router.push({ name: 'Crypto' })
    },
    wiseClick() {
      this.$router.push({ name: 'WiseP2p' })
    },
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode)
    },
    idPass() {
      return !this.requireIDProof && !this.pendingIDProofApproval
    },
    restrictedDeposit() {
      return this.$store.state.payment.restrictedDeposit
    },
  },
}
