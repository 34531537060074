<template>
  <el-row :gutter="24" class="row_card" type="flex">
    <!-- <el-col
      :xs="24"
      :sm="12"
      :md="8"
      @click.native="internationalClick"
      v-if="notRestricted(2)"
      data-testid="i12BankTransfer"
    >
      <el-card class="box_card">
        <div class="icon"><img src="@/assets/images/channel/swift.png" alt /></div>
        <span>{{ $t('deposit.default.channels.i12BankTransfer') }}</span>
        <p><b>$0</b> {{ $t('deposit.default.fee') }} | <b>2-5</b> {{ $t('deposit.default.instant') }}</p>
      </el-card>
    </el-col> -->
    <el-col :xs="24" :sm="12" :md="8" @click.native="cryptoClick" data-testid="crypto">
      <el-card class="box_card">
        <div class="icon"><img src="@/assets/images/channel/crypto.png" alt /></div>
        <span>{{ $t('deposit.default.channels.crypto') }}</span>
        <p><b>1</b> {{ $t('deposit.default.hour') }}</p>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="8" @click.native="creditClick" v-if="showCC" data-testid="creditOrDebit">
      <el-card class="box_card">
        <div class="icon"><img src="@/assets/images/channel/cc.png" alt /></div>
        <span>{{ $t('deposit.default.channels.creditOrDebitPrimary') }}</span>
        <p><b>$0</b> {{ $t('deposit.default.fee') }} | <b>24/7</b> {{ $t('deposit.default.instant') }}</p>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="8" @click.native="BanxaCCClick" v-if="showBanxa" data-testid="BanxaCC">
      <el-card class="box_card">
        <div class="icon"><img src="@/assets/images/channel/banxa.png" alt /></div>
        <span>{{ $t('deposit.default.channels.BanxaCC') }}</span>
        <p><b>$0</b> {{ $t('deposit.default.fee') }} | <b>24/7</b> {{ $t('deposit.default.instant') }}</p>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="8" @click.native="wiseClick" v-if="showWise" data-testid="creditOrDebit">
      <el-card class="box_card">
        <div class="icon"><img src="@/assets/images/channel/wise.png" alt /></div>
        <span>{{ $t('deposit.default.channels.EwalletWiseP2P') }}</span>
        <p><b>$0</b> {{ $t('deposit.default.fee') }} | <b>6 – 12 hours</b></p>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import mixin from '@/mixins/deposit';
import showCreditcard from '@/mixins/page/deposit/showCreditcard';
import showWise from '@/mixins/page/deposit/showWise';

export default {
  mixins: [mixin, showCreditcard, showWise]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit.scss';
</style>
