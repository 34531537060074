export default {
  data() {
    return {

      wiseP2pExcludeList: [
        3340, 3665, 3865, 7025, 7008, 4233, 4093, 6766, 6765, 7036, 4896, 5589, 6145, 7042, 3983, 6110, 6695, 6742, 6432, 7030, 7031, 6517]
    }
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode)
    },

    showWise() {
      return !this.wiseP2pExcludeList.includes(parseInt(this.countryCode))
    },

  },
}
