export default {
  data() {
    return {
      solidpayExcludeList: [
        3276, //Albania,
        3340, //Afghanistan,
        3588, //Bahamas,
        7046, //Barbados,
        3808, //Botswana,
        3795, //Bosnia and Herzegovina,
        4635, //Cambodia,
        4233, //Cuba,
        3423, //Ethiopia,
        4611, //Ghana,
        4254, //Guyana,
        6766, //Iran,
        6765, //Iraq,
        6713, //Jamaica,
        7036, //North Korea,
        4850, //Lao, People’s Democratic Republic of,
        5160, //Mauritius,
        5527, //Mongolia,
        5589, //Myanmar,
        5767, //Nicaragua,
        3589, //Pakistan,
        3635, //Panama,
        6057, //Sri Lanka,
        6110, //Sudan,
        6695, //Syria/Syrian Arab Republic,
        6277, //Trinidad and Tobago,
        6278, //Tunisia,
        6459, //Uganda,
        6396, //Vanuatu,
        6742, //Yemen,
        4676, //Zimbabwe,
        4575, //Canada,
        1, //China,
        4186, //Colombia,
        6767, //Israel,
        5176, //United States,
        6432, //Venezuela,
        3701, //Belgium,
        3674, //Bulgaria,
        5009, //Malta
        6845, // India
      ],
      // solidpayList: [ //允许国家
      //   3290, 3346, 3382, 3389, 3399, 3416, 3436, 3464, 3481, 3490, 3512, 3554, 3588, 3589, 3600, 3624, 3635, 3673, 3674, 3685, 3686, 3701, 3714, 3716, 3781, 3808, 3809, 3817, 3818, 3899, 3915, 3959, 3974, 3981, 3982, 4070, 4101, 4126, 4127, 4131, 4137, 4138, 4160, 4183, 4186, 4221, 4231, 4232, 4254, 4266, 4482, 4514, 4535, 4540, 4546, 4575, 4611, 4624, 4635, 4661, 4688, 4700, 4711, 4713, 4735, 4759, 4809, 4810, 4838, 4850, 4897, 4910, 4912, 4917, 4935, 4978, 4986, 5009, 5010, 5015, 5156, 5160, 5161, 5511, 5527, 5557, 5558, 5605, 5606, 5617, 5618, 5619, 5681, 5696, 5748, 5750, 5751, 5786, 5796, 5804, 5826, 5828, 5859, 5860, 5909, 5932, 5961, 5997, 6003, 6016, 6024, 6025, 6050, 6053, 6054, 6056, 6057, 6084, 6094, 6109, 6121, 6133, 6146, 6163, 6268, 6277, 6278, 6304, 6305, 6385, 6396, 6404, 6431, 6458, 6459, 6544, 6565, 6581, 6633, 6647, 6673, 6713, 6729, 6777, 6845, 6877, 6907, 6976, 6991, 6996, 7008, 7010, 7028, 7037, 7039, 7040, 7041, 7043, 7044, 7045, 7046, 7047, 7048, 7049, 7050, 7051, 7052, 7055, 7056, 7057, 7058, 7059, 7060, 7061, 7062, 7063, 7064, 7065, 7066, 7069, 7070, 7071, 7073, 7074, 7075, 7076, 7077, 7079, 7080, 7081, 7082, 7083, 7084, 7085, 7087, 7089, 7091, 7092, 7095, 7096, 7097, 8000, 8001,
      // ],
      virtualpayExcludeList: [
        3340, 3276, 3665, 3795, 3636, 7025, 7031, 4233, 3423, 7033, 6766, 6765, 6767, 7036, 7034, 4869, 4896, 5144, 7035, 5589, 5767, 5155, 3983, 5987, 6145, 7042, 6110, 6695, 6517, 5176, 6432, 6742, 4676, 7030
      ],
      banxaPayExcludelist: [
        3340, 3665, 4711, 7030, 7025, 1, 4233, 4564, 4305, 6766, 6765, 4869, 4896, 5144, 5015, 5589, 5618, 5767, 3983, 6145, 6110, 6110, 6695, 6278, 6432, 4676, 6742, 7041
      ]
    }
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode)
    },
    showSolidpay() {
      // return !this.solidpayExcludeList.includes(parseInt(this.countryCode))
      return true
    },
    showvirtualpay() {
      return !this.virtualpayExcludeList.includes(parseInt(this.countryCode))
    },
    showCC() {
      return this.showSolidpay
    },
    showBanxa() {
      return !this.banxaPayExcludelist.includes(parseInt(this.countryCode))
    }
  },
}
